import { useCallback } from 'react';

import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import axios from 'axios';
import qs from 'qs';

// const SURVEY_ID = 1;

const surveyJson = {
  //title: "5G服務滿意度調查表-消費者端(2B04)_5G跨屏多人互動遊戲服務",
  title: "5G跨屏遊戲_填問卷獲得 iPhone 15 抽獎機會!!!",
  //description: "填完問卷即可獲得iPhone抽獎機會!!!",
  logoPosition: "right",
  focusFirstQuestionAutomatic: false,
  showQuestionNumbers: "off",
  checkErrorsMode: "onNextPage",
  completeText: "完成",
  pagePrevText: "上一頁",
  pageNextText: "下一頁",
  widthMode: "responsive",
  completedHtml: '<h3>感謝您的填寫<br/><a href="https://line.me/R/app/2003700544-10KqyvKk">領取抽獎券</a></h3>',
  questionErrorLocation: "bottom",
  showProgressBar: "top",
  progressBarType: "pages",
  progressBarShowPageNumbers: true,
  progressBarShowPageTitles: true,
  showPrevButton: true,
  //showPreviewBeforeComplete: "showAnsweredQuestions",
  pages: [
  {
    navigationTitle: "1",
    name: "part1_p1",
    title: "第一部分：本次體驗狀況調查",
    elements: [
      {
        type: "panel",
        name: "p1",
        elements: [
          {
            name: "Q1",
            title: "1 您在何種網路環境下體驗本次5G應用服務？",
            type: "radiogroup",
            choices: [
              { value: "5G", text: "5G" },
              { value: "4G", text: "4G" },
              { value: "WIFI", text: "WIFI" },
              { value: "其他", text: "其他" }
            ],
            isRequired: true
          }
        ]
      }
    ]
  },
  {
    navigationTitle: "2",
    name: "part1_p2",
    title: "第一部分：本次體驗狀況調查",
    elements: [
      {
        type: "panel",
        name: "p2",
        elements: [{
          name: "Q2",
          title: "2 您從何得知活動訊息？(複選)",
          type: "checkbox",
          choices: [
            "行動廣告車",
            "網路資訊（店家官網、社群、email等）",
            "剛好路過",
            "親朋好友分享",
            "其他"
          ],
          isRequired: true
        }]
      }
    ]
  },
  {
    navigationTitle: "3",
    name: "part1_p3",
    title: "第一部分：本次體驗狀況調查",
    elements: [
      {
        type: "panel",
        name: "panel_q3",
        title: "3 本次體驗「5G跨屏多人互動遊戲服務」滿意度（1~7分，非常滿意7分，非常不滿意1分）",
        elements: [
          {
            type: "panel",
            name: "q3_01",
            title: "內容豐富度",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_01_rg",
                valueName: "q3_01_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_02",
            title: "內容趣味性",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_02_rg",
                valueName: "q3_02_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_03",
            title: "內容創新性",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_03_rg",
                valueName: "q3_03_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_04",
            title: "系統易用性，例如不需註冊會員，或註冊會員是容易的",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_04_rg",
                valueName: "q3_04_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_05",
            title: "操作流程順暢度，例如體驗流程及系統畫面容易理解",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_05_rg",
                valueName: "q3_05_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_06",
            title: "資料傳輸速度，例如內容上傳、下載快速、低延遲",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_06_rg",
                valueName: "q3_06_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_07",
            title: "系統穩定度，例如畫面流暢，資訊傳輸穩定",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_07_rg",
                valueName: "q3_07_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q3_08",
            title: "本次體驗「5G跨屏多人互動遊戲服務」之整體滿意度",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q3_08_rg",
                valueName: "q3_08_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    navigationTitle: "4",
    name: "part1_p4",
    title: "第一部分：本次體驗狀況調查",
    elements: [
      {
        type: "panel",
        name: "panel_q4",
        title: "4 經過本次體驗「5G跨屏多人互動遊戲服務」，針對以下題目勾選同意程度（1~7分，非常同意7分，非常不同意1分）",
        elements: [
          {
            type: "panel",
            name: "q4_01",
            title: "我願意在社群媒體分享本次體驗經驗",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q4_01_rg",
                valueName: "q4_01_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
          {
            type: "panel",
            name: "q4_02",
            title: "我願意向別人推薦「5G跨屏多人互動遊戲服務」",
            questionTitleLocation: "top",
            elements: [
              {
                type: "radiogroup",
                colCount: 0,
                title: " ",
                name: "q4_02_rg",
                valueName: "q4_02_rg",
                isRequired: true,
                choices: [
                  {
                    value: "7",
                    text: "7分非常滿意"
                  },
                  {
                    value: "6",
                    text: "6分滿意"
                  },
                  {
                    value: "5",
                    text: "5分有點滿意"
                  },
                  {
                    value: "4",
                    text: "4分普通"
                  },
                  {
                    value: "3",
                    text: "3分有點不滿意"
                  },
                  {
                    value: "2",
                    text: "2分不滿意"
                  },
                  {
                    value: "1",
                    text: "1分非常不滿意"
                  }
                ]
              },
            ]
          },
        ]
      },
    ]
  },
  {
    navigationTitle: "5",
    name: "part1_p5",
    title: "第一部分：本次體驗狀況調查",
    elements: [
      {
        type: "panel",
        name: "panel_q5",
        elements: [{
          name: "Q5",
          title: "5 經過本次體驗「5G跨屏多人互動遊戲服務」，此項應用服務需要改進之處？(複選)",
          type: "checkbox",
          choices: [
            "無",
            "簡化操作流程",
            "減少應用服務系統加載時間",
            "改善網路連線狀況",
            "優化系統功能（如：AI辨識）",
            "強化資料保密安全性",
            "增加優惠方案（如：優惠券）",
            "增加客服和意見回饋機制",
            "提升體驗互動性",
            "提升服務/商品內容多樣化（如：遊戲模組、拍攝模板）",
            "付費機制（如：費用調整、增加多元支付）",
            "減少廣告時間",
            "其他",
          ],
          isRequired: true
        }]
      }
    ]
  },
  {
    navigationTitle: "6",
    name: "part2_p1",
    title: "第二部分：基本資料",
    elements: [
      {
        type: "panel",
        name: "p1",
        elements: [
          {
            title: "6 請填寫Line ID或手機號(將使用於抽獎)",
            type: "text",
            name: "lineId",
            isRequired: true
          },
          {
            title: "7 性別",
            type: "radiogroup",
            name: "gender",
            choices: [
              { value: "生理男", text: "生理男" },
              { value: "生理女", text: "生理女" },
            ],
            isRequired: true
          },
          {
            title: "8 年齡",
            type: "radiogroup",
            name: "age",
            choices: [
              { value: "20歲以下", text: "20歲以下" },
              { value: "21~30歲", text: "21~30歲" },
              { value: "31~40歲", text: "31~40歲" },
              { value: "41~50歲", text: "41~50歲" },
              { value: "51~60歲", text: "51~60歲" },
              { value: "61~70歲", text: "61~70歲" },
              { value: "70歲以上", text: "70歲以上" }
            ],
            isRequired: true
          },
          {
            title: "9 教育程度",
            type: "radiogroup",
            name: "education",
            choices: [
              { value: "國小", text: "國小" },
              { value: "國中", text: "國中" },
              { value: "高中（職）", text: "高中（職）" },
              { value: "大專（學）", text: "大專（學）" },
              { value: "研究所（以上）", text: "研究所（以上）" },
            ],
            isRequired: true
          },
          {
            title: "10 居住縣市",
            type: "dropdown",
            name: "city",
            minWidth: "220px",
            titleLocation: "top",
            choices: [
              {
                value: "台北市",
                text: "台北市"
              },
              {
                value: "基隆市",
                text: "基隆市"
              },
              {
                value: "新北市",
                text: "新北市"
              },
              {
                value: "宜蘭縣",
                text: "宜蘭縣"
              },
              {
                value: "桃園市",
                text: "桃園市"
              },
              {
                value: "新竹市",
                text: "新竹市"
              },
              {
                value: "新竹縣",
                text: "新竹縣"
              },
              {
                value: "苗栗縣",
                text: "苗栗縣"
              },
              {
                value: "台中市",
                text: "台中市"
              },
              {
                value: "彰化縣",
                text: "彰化縣"
              },
              {
                value: "南投縣",
                text: "南投縣"
              },
              {
                value: "嘉義市",
                text: "嘉義市"
              },
              {
                value: "嘉義縣",
                text: "嘉義縣"
              },
              {
                value: "雲林縣",
                text: "雲林縣"
              },
              {
                value: "台南市",
                text: "台南市"
              },
              {
                value: "高雄市",
                text: "高雄市"
              },
              {
                value: "澎湖縣",
                text: "澎湖縣"
              },
              {
                value: "金門縣",
                text: "金門縣"
              },
              {
                value: "屏東縣",
                text: "屏東縣"
              },
              {
                value: "台東縣",
                text: "台東縣"
              },
              {
                value: "花蓮縣",
                text: "花蓮縣"
              },
              {
                value: "連江縣",
                text: "連江縣"
              }
            ],
            isRequired: true,
          },
          {
            title: "11 您是否曾使用5G網路？",
            type: "radiogroup",
            name: "use5gNetwork",
            choices: [
              { value: "是", text: "是" },
              { value: "否", text: "否" },
            ],
            isRequired: true
          },
          {
            title: "12 您目前是否使用5G手機門號？",
            type: "radiogroup",
            name: "own5gPhoneNum",
            choices: [
              { value: "是", text: "是" },
              { value: "否", text: "否" },
            ],
            isRequired: true
          },
          {
            title: "13 在本次體驗後是否有意願更換(繼續使用)5G手機門號？",
            type: "radiogroup",
            name: "switch5gPhoneNum",
            choices: [
              { value: "是", text: "是" },
              { value: "否", text: "否" },
            ],
            isRequired: true
          },
        ]
      }
    ]
  },
]
};

// survey json output example
/*
{
   "Q1":"其他",
   "Q2":[
      "其他"
   ],
   "q3_01_rg":"1",
   "q3_02_rg":"7",
   "q3_03_rg":"7",
   "q3_04_rg":"7",
   "q3_05_rg":"7",
   "q3_06_rg":"7",
   "q3_07_rg":"7",
   "q3_08_rg":"7",
   "q4_01_rg":"7",
   "q4_02_rg":"7",
   "Q5":[
      "簡化操作流程",
      "減少應用服務系統加載時間"
   ],
   "lineId":"poiu",
   "gender":"生理男",
   "age":"20歲以下",
   "education":"國小",
   "city":"新竹市",
   "use5gNetwork":"是",
   "own5gPhoneNum":"是",
   "switch5gPhoneNum":"是"
}
*/
function App() {
  const survey = new Model(surveyJson);
  const alertResults = useCallback(async(sender) => {
    const surveyJsonStr = JSON.stringify(sender.data);
    //console.log(surveyJsonStr);
    const surveyJsonB64 = btoa(unescape(encodeURIComponent(surveyJsonStr)));
    //console.log('surveyJsonB64', surveyJsonB64);

    //const surveyJson = decodeURIComponent(escape(atob(surveyJsonB64)));
    //console.log('surveyJson', surveyJson);

    const formData = {
      accessToken: process.env.REACT_APP_ACCESS_TOKEN,
      rawdata: surveyJsonB64,
    }
    const uri = process.env.REACT_APP_BACKEND_URL + '/api/add/survey';
    //console.log('uri', uri, 'formData', formData);

    const info = {};
    await axios.post(uri, qs.stringify(formData), {})
      .then(function (response) {
        console.log('response', response);
        info.result = response.result;
        info.msg = response.msg;
        info.qrcode = response.qrcode;
      })
      .catch(function (error) {
        console.log('err message', error);
        //res.status(500).send(error);
      });


    // saveSurveyResults(
    //   "https://your-web-service.com/" + SURVEY_ID,
    //   sender.data
    // )
  }, []);

  survey.onComplete.add(alertResults);

  return <Survey model={survey} />;
}
/*
    <div align="center">
      <img src="https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl=5d4204df4545df31ad5132dbb54149f9" />
    </div>
*/
// function saveSurveyResults(url, json) {
//   fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     body: JSON.stringify(json)
//   })
//   .then(response => {
//     if (response.ok) {
//       // Handle success
//     } else {
//       // Handle error
//     }
//   })
//   .catch(error => {
//     // Handle error
//   });
// }

export default App;
